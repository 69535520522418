import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { LocaleConfig, useTranslations } from '../core'

interface PostListPaginationProps {
    locale: LocaleConfig
    currentPage: number
    numPages: number
}

export const PostListPagination = ({
    locale,
    currentPage,
    numPages,
}: PostListPaginationProps) => {
    const { translate } = useTranslations(locale.locale)

    if (numPages === 1) {
        return null
    }

    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage =
        currentPage === 2
            ? '/english-learning-blog/'
            : `/english-learning-blog/${currentPage - 1}/`
    const nextPage = `/english-learning-blog/${currentPage + 1}/`

    return (
        <Container>
            {!isFirst ? (
                <NextLink to={prevPage} rel="next">
                    &lt; {translate('newer_posts')}
                </NextLink>
            ) : (
                <span />
            )}
            <Pages>
                {Array.from({ length: numPages }, (_, i) => (
                    <PageItem
                        key={`pagination-number${i + 1}`}
                        to={`/${
                            i === 0
                                ? 'english-learning-blog'
                                : `english-learning-blog/${i + 1}`
                        }/`}
                        className={i + 1 === currentPage ? '_is-current' : ''}
                    >
                        {i + 1}
                    </PageItem>
                ))}
            </Pages>
            {!isLast ? (
                <PreviousLink to={nextPage} rel="prev">
                    {translate('older_posts')} &gt;
                </PreviousLink>
            ) : (
                <span />
            )}
        </Container>
    )
}

const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 120px;
    height: 40px;
`

const Pages = styled.div`
    display: flex;
    justify-content: center;

    @media (max-width: 500px) {
        display: none;
    }
`

const PageItem = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    text-decoration: none;
    background-color: ${({ theme }) => theme.colors.lightBackground};
    border-left-width: 0;
    font-size: 12px;
    color: #555555;
    margin-left: 3px;
    border-radius: 3px;

    &:first-child {
        margin-left: 0;
    }
    &._is-current {
        font-weight: 900;
        color: black;
        font-family: ${({ theme }) => theme.typography.bolderFontFamily};
    }
`

const EdgeLink = styled(Link)`
    position: absolute;
    top: 0;
    background-color: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.invertedBackground};
    border-radius: 3px;
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    white-space: nowrap;
    color: #4d4d4d;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.colors.invertedBackground};
        color: ${({ theme }) => theme.colors.invertedText};
    }
`

const PreviousLink = styled(EdgeLink)`
    right: 0;
`

const NextLink = styled(EdgeLink)`
    left: 0;
`
