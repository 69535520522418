import React from 'react'
import styled from 'styled-components'
import { Container, LocaleConfig, SectionSpacer } from '../core'
import { PostCard } from './PostCard'
import { PostListPagination } from './PostListPagination'

export type PostCategory = 'media' | 'updates' | 'blog'

export interface Post {
    title: string
    description: string
    illustration: string
    category: PostCategory
    path: string
}

interface PostListProps {
    locale: LocaleConfig
    posts: Array<Post>
    currentPage: number
    numPages: number
}

export const PostList = ({
    locale,
    posts,
    currentPage,
    numPages,
}: PostListProps) => {
    return (
        <Container>
            <Wrapper>
                {posts.map((post) => (
                    <PostCard
                        key={post.path}
                        title={post.title}
                        illustration={post.illustration}
                        category={post.category}
                        path={post.path}
                        locale={locale}
                    />
                ))}
            </Wrapper>
            <SectionSpacer />
            <PostListPagination
                locale={locale}
                currentPage={currentPage}
                numPages={numPages}
            />
        </Container>
    )
}

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`
