import React from 'react'
import { graphql } from 'gatsby'
import {
    Layout,
    SEO,
    PageTitle,
    SectionSpacer,
    useTranslations,
    LocaleConfig,
} from '../core'
import { FeaturedPost } from './FeaturedPost'
import { PostList } from './PostList'
import { PostsCollectionData, normalizePostsData } from './collection'

interface IndexPageProps {
    path: string
    pageContext: {
        currentPage: number
        numPages: number
        localeConfig: LocaleConfig
    }
    data: PostsCollectionData
}

export const query = graphql`
    query BlogIndexPageQuery($locale: String!, $skip: Int!, $limit: Int!) {
        allMdx(
            filter: {
                fileAbsolutePath: { glob: "**/posts/*.mdx" }
                frontmatter: { locale: { eq: $locale } }
            }
            limit: $limit
            skip: $skip
            sort: { fields: frontmatter___date, order: DESC }
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        description
                        illustration
                        category
                        path
                    }
                }
            }
        }
        allFile(filter: { relativePath: { glob: "posts/*.png" } }) {
            edges {
                node {
                    name
                    extension
                    childImageSharp {
                        gatsbyImageData(
                            layout: FIXED
                            height: 482
                            quality: 100
                        )
                    }
                }
            }
        }
    }
`

const IndexPage = ({ path, pageContext, data }: IndexPageProps) => {
    const { translate } = useTranslations(pageContext.localeConfig.locale)

    const posts = normalizePostsData(data)
    const featuredPost = posts[0]

    const { currentPage, numPages } = pageContext

    return (
        <Layout locale={pageContext.localeConfig} path={path}>
            <SEO title={translate('page_blog')} />
            <PageTitle>{translate('page_blog')}</PageTitle>
            <FeaturedPost
                title={featuredPost.title}
                description={featuredPost.description}
                illustration={featuredPost.illustration}
                category={featuredPost.category}
                path={featuredPost.path}
                locale={pageContext.localeConfig}
            />
            <SectionSpacer />
            <PostList
                locale={pageContext.localeConfig}
                posts={posts}
                currentPage={currentPage}
                numPages={numPages}
            />
        </Layout>
    )
}

export default IndexPage
